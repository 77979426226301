export const BASE_URL = 'https://cotizador.backofficecargo-sa.com:8443';
//export const BASE_URL = 'http://localhost:8889'; //TEST
export const APP_BASE_URL = BASE_URL + '/app/v1/'
export const ANALYTICS_BASE_URL = BASE_URL + '/analytics/v1/'; //ANALYTICS
export const WS_BASE_URL = BASE_URL + '/ws/v1/ws/';
export const VERSION = 4;

export const PERMISSION = {
    ALTA_COTIZACION: "ALTA_COTIZACION",
    ALTA_ORGANIZACION: "ALTA_ORGANIZACION",
    ALTA_USUARIO: "ALTA_USUARIO",
    BAJA_USUARIO: "BAJA_USUARIO",
    CONSULTAR_AGENTE: "CONSULTAR_AGENTE",
    CONSULTAR_CLIENTE: "CONSULTAR_CLIENTE",
    CONSULTAR_COMMODITY: "CONSULTAR_COMMODITY",
    CONSULTAR_COTIZACION: "CONSULTAR_COTIZACION",
    CONSULTAR_COUNTRY: "CONSULTAR_COUNTRY",
    CONSULTAR_ORGANIZACION: "CONSULTAR_ORGANIZACION",
    CONSULTAR_UNLOCO: "CONSULTAR_UNLOCO",
    CONSULTAR_USUARIO: "CONSULTAR_USUARIO",
    CONSULTAR_SPOT: "CONSULTAR_SPOT",
    MODIFICAR_COTIZACION_COSTOS: "MODIFICAR_COTIZACION_COSTOS",
    MODIFICAR_COTIZACION_VENTAS: "MODIFICAR_COTIZACION_VENTAS",
    MODIFICAR_ORGANIZACION: "MODIFICAR_ORGANIZACION",
    MODIFICAR_SPOT: "MODIFICAR_SPOT",
    MODIFICAR_TARIFA_EXPRESS: "MODIFICAR_TARIFA_EXPRESS",
    MODIFICAR_USUARIO: "MODIFICAR_USUARIO",
    SOLICITAR_COTIZACION: "SOLICITAR_COTIZACION",
    PRICING: "PRICING",
    COMERCIAL: "COMERCIAL",
    GERENTE_COMERCIAL: "GERENTE_COMERCIAL",
    ALTA_CHARGE_CODE: "ALTA_CHARGE_CODE",
    CONSULTAR_CHARGE_CODE: "CONSULTAR_CHARGE_CODE",
    BAJA_CHARGE_CODE: "BAJA_CHARGE_CODE",
    BAJA_COTIZACION: "BAJA_COTIZACION",
    CONSULTAR_SHIPMENT: "CONSULTAR_SHIPMENT",
    MODIFICAR_SHIPMENT: "MODIFICAR_SHIPMENT",
    SOLICITAR_SHIPMENT: "SOLICITAR_SHIPMENT",
    EXPRESS_OP: "EXPRESS_OP",
    CONSULTAR_STATS: "CONSULTAR_STATS",
    PRICING_RULES: "PRICING_RULES",
    ANALYTICS_INVOICED: "ANALYTICS_INVOICED",
    ANALYTICS_QUOTATIONS: "ANALYTICS_QUOTATIONS",
    ANALYTICS_REPORTS: "ANALYTICS_REPORTS"
};

export const ROLES = {
    COMMERCIAL: "COMMERCIAL",
    PRICING_AIR: "PRICING_AIR",
    PRICING_SEA: "PRICING_SEA",
    PRICING_ROAD: "PRICING_ROAD",
    OPERATIONS_AIR: "OPERATIONS_AIR",
    OPERATIONS_SEA: "OPERATIONS_SEA",
    OPERATIONS_ROAD: "OPERATIONS_ROAD",
    ADMINISTRATIVE: "ADMINISTRATIVE",
    ADMIN: "ADMIN",
};

export const COLORS = {
    PRIMARY: "#456a4b",
    SECONDARY: "#EBEBEB",
    TERTIARY: "#babec2",
    GRAY_100: "#565656",
}

export const TRUE_FALSE_OPTIONS = [
    {value: 'true', label: 'SI'},
    {value: 'false', label: 'NO'},
];

export const CURRENCIES_OPTIONS = [
    {value: 'USD', label: 'USD'},
    {value: 'ARS', label: 'ARS'},
    {value: 'EUR', label: 'EUR'},
];

export const IVA = {
    percentage: 21,
    multiplier: 0.21,
}

export const QUOTATION_DOCUMENT_TYPES = [
    {value: "PKL", label: "PKL"},
    {value: "CIV", label: "CIV"},
    {value: "MSDS", label: "MSDS"},
    {value: "MSC", label: "OTROS"},
]

export const SHIPMENT_DOCUMENT_TYPES = [
    ...QUOTATION_DOCUMENT_TYPES,
    {value: "FCO", label: "FCO"},
    {value: "AWB", label: "AWB"},
]

export const INCOTERM_OPTIONS = [
    {value: "EXW", label: "EXW"},
    {value: "FCA", label: "FCA"},
    {value: "FAS", label: "FAS"},
    {value: "FOB", label: "FOB"},
    {value: "CFR", label: "CFR"},
    {value: "CIF", label: "CIF"},
    {value: "CIP", label: "CIP"},
    {value: "CPT", label: "CPT"},
    {value: "DAP", label: "DAP"},
    {value: "DPU", label: "DPU"},
    {value: "DDP", label: "DDP"},
]

export const TRANSPORT_OPTIONS = {
    SEA: [
        {value: "FCL", label: "Maritimo - FCL"},
        {value: "LCL", label: "Maritimo - LCL"},
        {value: "RORO", label: "Maritimo - RORO"},
    ],
    AIR: [
        {value: "ULD", label: "Aéreo - ULD"},
        {value: "LSE", label: "Aéreo - LSE"},
    ],
    ROAD: [
        {value: "FTL", label: "Terrestre - FTL"},
        {value: "LTL", label: "Terrestre - LTL"},
    ],
}

export const QUOTE_STATUS = {
    REQUESTED: "REQUESTED",
    PENDING: "PENDING",
    QUOTED: "QUOTED",
    UPDATED: "UPDATED",
    SENT: "SENT",
    APPROVED: "APPROVED",
    NOT_APPROVED: "NOT_APPROVED",
    FUTURE: "FUTURE",
}

export const EXPRESS_QUOTE_STATUS = {
    NOT_REQUESTED: "NOT_REQUESTED",
    REQUESTED: "REQUESTED",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
}

export const INFO = ["description", "validez", "ruta", "ttime", "carrier", "freeDays"];

export const TRANSLATION_KEYS = {
    DATE: 'date',
    DATE_WEEK: 'dateWeek',
    DATE_MONTH: 'dateMonth',
    SERVICE: 'service',
    STATUS: 'status',
    COMMERCIAL: 'commercial',
    PROVIDER: 'provider',
    TRANSPORT: 'transport',
    MODE: 'mode',
    TRANSPORT_MODE: 'transportMode',
    CONTAINERS: 'containers',
    TRUCKS: 'trucks',
    CONTAINER_TYPE: 'containerType',
    ORIGIN: 'origin',
    ORIGINS: 'origins',
    DESTINATION: 'destination',
    DESTINATIONS: 'destinations',
    INCOTERM: 'incoterm',
    PACKING: 'packing',
    PICKUP: 'pickups',
    COMMODITY: 'commodity',
    COMMODITIES: 'commodities',
    GOODS_VALUE: 'goodsValue',
    INSURANCE_VALUE: 'insuranceValue',
    TOTAL_WEIGHT: 'totalWeight',
    TOTAL_VOLUME: 'totalVolume',
    TOTAL_CHARGEABLE: 'totalChargeable',
    TOTAL_CARTONS: 'totalCartons',
    TOTAL_PALLETS: 'totalPallets',
    STACKABLE: 'stackable',
    VIEW_DOCS: 'viewDocs',
    VIEW_NOTES: 'viewNotes',
    DOCUMENTS: 'documents',
    NOTES: 'notes',
    CODE: 'code',
    CONCEPT: 'concept',
    CONCEPT_EDITABLE: 'conceptEditable',
    TOTAL: 'total',
    PROVIDER_REFERENCE: 'providerReference',
    TOOLTIP_PROVIDER_REFERENCE: 'tooltipProviderReference',
    DESCRIPTION: 'description',
    VALIDITY: 'validity',
    ROUTE: 'route',
    TRANSIT_TIME: 'transitTime',
    TRANSIT_TIME_FILTER: 'transitTimeFilter',
    CARRIER: 'carrier',
    FREE_DAYS: 'freeDays',
    COMMENTS: 'comments',
    COMMENTS_AND_CONDITIONS: 'commentsAndConditions',
    COST: 'cost',
    QUANTITY: 'quantity',
    WEIGHT: 'weight',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    RANGE: 'range',
    RANGE_WEIGHT: 'rangeWeight',
    RANGE_SIZE: 'rangeSize',
    ADD_NEW_COST: 'addNewCost',
    ERROR_ALERT: 'errorAlert',
    ERROR_WHILE_GETTING_QUOTATION: 'errorWhileGettingQuotation',
    ERROR_CANNOT_ADD_MORE_CONCEPTS: 'errorCannotAddMoreConcepts',
    ERROR_AT_LEAST_ONE_COST: 'errorAtLeastOneCost',
    ERROR_AT_LEAST_ONE_CHARGE: 'errorAtLeastOneCharge',
    ERROR_AT_LEAST_ONE_VALUE: 'errorAtLeastOneValue',
    ERROR_EVERY_CHARGE_NEEDS_A_CODE: 'errorEveryChargeNeedsACode',
    ERROR_WRONG_DATE_FORMAT: 'errorWrongDateFormat',
    ERROR_SENDING_COSTS: 'errorSendingCosts',
    SEND_COSTS: 'sendCosts',
    SEND_COSTS_CONFIRMATION: 'sendCostsConfirmation',
    SEND_COSTS_SUCCESS: 'sendCostsSuccess',
    SUCCESS_ALERT: 'successAlert',
    TOOLTIP_COST_PER_KG: 'tooltipCostPerKg',
    TOOLTIP_DELETE_CHARGE: 'tooltipDeleteCharge',
    TOOLTIP_DELETE_COST: 'tooltipDeleteCost',
    TOOLTIP_ADD_CHARGE: 'tooltipAddCharge',
    TOOLTIP_ADD_COST: 'tooltipAddCost',
    TOOLTIP_DUPLICATE_COST: 'tooltipDuplicateCost',
    COST_ALREADY_QUOTED: 'costAlreadyQuoted',
    QUOTATION_HAS_BEEN_CANCELED: 'quotationHasBeenCanceled',
    CLOSE: 'close',
    YES: 'yes',
    NO: 'no',
    SHORTCUT: 'shortcut',
    SETTINGS: 'settings',
    MAXIMIZE: 'maximize',
    MINIMIZE: 'minimize',
    LIMITED_INPUT: 'limitedInput',
    ACCUMULATED: 'accumulated',
    DAILY: 'daily',
    DAILY_AVERAGE: 'dailyAverage',
    REQUESTED: 'requested',
    SENT: 'sent',
    PENDING: 'pending',
    UPDATED: 'updated',
    QUOTED: 'quoted',
    APPROVED: 'approved',
    NOT_APPROVED: 'notApproved',
    FUTURE: 'future',
    EXPRESS: 'express',
    SEA: 'sea',
    AIR: 'air',
    ROAD: 'road',
    AGENT: 'agent',
    PRICING: 'pricing',
    REQUESTS: 'requests',
    QUOTATIONS: 'quotations',
    TOTAL_QUOTATIONS: 'totalQuotations',
    TOTAL_REQUESTS: 'totalRequests',
    EFFICIENCY: 'efficiency',
    NOT_FOUND: 'notFound',
    SERVER_ERROR: 'serverError',
    NOTHING_SELECTED: 'nothingSelected',
    DASHBOARD_TAB_GENERAL: 'dashboardTabGeneral',
    DASHBOARD_TAB_GENERAL_HELP_1: 'dashboardTabGeneralHelp1',
    DASHBOARD_TAB_FCL_HELP_1: 'dashboardTabFclHelp1',
    DASHBOARD_FULLSCREEN_MODE_ON: 'dashboardFullScreenModeOn',
    DASHBOARD_FULLSCREEN_MODE_OFF: 'dashboardFullScreenModeOff',
    DASHBOARD_FULLSCREEN_MODE_DESCRIPTION: 'dashboardFullScreenModeDescription',
    DASHBOARD_PRESENTATION_MODE_ON: 'dashboardPresentationModeOn',
    DASHBOARD_PRESENTATION_MODE_OFF: 'dashboardPresentationModeOff',
    DASHBOARD_PRESENTATION_MODE_DESCRIPTION_1: 'dashboardPresentationModeDescription1',
    DASHBOARD_PRESENTATION_MODE_DESCRIPTION_2: 'dashboardPresentationModeDescription2',
    DASHBOARD_CLEAR_FILTERS: 'dashboardClearFilters',
    DASHBOARD_CLEAR_FILTERS_DESCRIPTION: 'dashboardClearFiltersDescription',
    DASHBOARD_MONTHLY_RESULTS: 'dashboardMonthlyResults',
    DASHBOARD_CUSTOM_Y_AXIS: 'dashboardCustomYAxis',
    DASHBOARD_Y_AXIS_LIMIT: 'dashboardYAxisLimit',
    DASHBOARD_COMMERCIAL_MONTH: 'dashboardCommercialMonth',
    DASHBOARD_QUOTATION_EFFICIENCY: 'dashboardQuotationEfficiency',
    DASHBOARD_QUICK_VIEW: 'dashboardQuickView',
    DASHBOARD_USE_POLYGONAL_BACKGROUND: 'dashboardUsePolygonalBackground',
    DASHBOARD_USE_CIRCULAR_BACKGROUND: 'dashboardUseCircularBackground',
    DASHBOARD_USE_NUMBERS: 'dashboardUseNumbers',
    DASHBOARD_USE_PERCENTAGES: 'dashboardUsePercentages',
    DASHBOARD_USE_POLAR_CHART: 'dashboardUsePolarChart',
    DASHBOARD_USE_STACKED_CHART: 'dashboardUseStackedChart',
    DASHBOARD_SHOW_EFFICIENCY: 'dashboardShowEfficiency',
    DASHBOARD_SHOW_TOTAL: 'dashboardShowTotal',
    DASHBOARD_USE_BAR_CHART: 'dashboardUseBarChart',
    DASHBOARD_PERCENTAGE: 'dashboardPercentage',
    DASHBOARD_WAITING_TIME: 'dashboardWaitingTime',
    DASHBOARD_WAITING_TIME_HELP_1: 'dashboardWaitingTimeHelp1',
    DASHBOARD_WAITING_TIME_HELP_2: 'dashboardWaitingTimeHelp2',
    DASHBOARD_MONTHLY_QUOTATIONS: 'dashboardMonthlyQuotations',
    DASHBOARD_VISUALIZING_INFO: 'dashboardVisualizingInfo',
    DASHBOARD_AIR_COUNT: 'dashboardAirCount',
    DASHBOARD_FCL_COUNT: 'dashboardFclCount',
    DASHBOARD_LCL_COUNT: 'dashboardLclCount',
    DASHBOARD_RORO_COUNT: 'dashboardRoroCount',
    DASHBOARD_FTL_COUNT: 'dashboardFtlCount',
    DASHBOARD_LTL_COUNT: 'dashboardLtlCount',
    DASHBOARD_COMMERCIAL_RANKING: 'dashboardCommercialRanking',
    DASHBOARD_POSITION: 'dashboardPosition',
    DASHBOARD_MOST_REQUESTED: 'dashboardMostRequested',
    DASHBOARD_TOTALS: 'dashboardTotals',
    DASHBOARD_AIR_SUMMARY: 'dashboardAirSummary',
    DASHBOARD_AIR_HISTORY: 'dashboardAirHistory',
    DASHBOARD_LSE_SUMMARY: 'dashboardLseSummary',
    DASHBOARD_LSE_HISTORY: 'dashboardLseHistory',
    DASHBOARD_ULD_SUMMARY: 'dashboardUldSummary',
    DASHBOARD_ULD_HISTORY: 'dashboardUldHistory',
    DASHBOARD_LCL_SUMMARY: 'dashboardLclSummary',
    DASHBOARD_LCL_HISTORY: 'dashboardLclHistory',
    DASHBOARD_FCL_SUMMARY: 'dashboardFclSummary',
    DASHBOARD_FCL_HISTORY: 'dashboardFclHistory',
    DASHBOARD_HISTORY_DESCRIPTION_CARRIER: 'dashboardHistoryDescriptionCarrier',
    DASHBOARD_HISTORY_DESCRIPTION_PROVIDER: 'dashboardHistoryDescriptionProvider',
    DASHBOARD_ADD_COST_COMPARISON: 'dashboardAddCostComparison',
    DASHBOARD_REMOVE_COST_COMPARISON: 'dashboardRemoveCostComparison',
    DASHBOARD_VIEW_DETAILS: 'dashboardViewDetails',
    DASHBOARD_ADD_ALL_COSTS: 'dashboardAddAllCosts',
    DASHBOARD_REMOVE_ALL_COSTS: 'dashboardRemoveAllCosts',
    DASHBOARD_USE_CUSTOM_AXIS_LIMIT: 'dashboardUseCustomAxisLimit',
    DASHBOARD_USE_CUSTOM_AXIS_LIMIT_DESCRIPTION: 'dashboardUseCustomAxisLimitDescription',
    DASHBOARD_USE_WEEKS: 'dashboardUseWeeks',
    DASHBOARD_USE_MONTHS: 'dashboardUseMonths',
    DASHBOARD_USE_PROVIDER_VIEW: 'dashboardUseProviderView',
    DASHBOARD_USE_CARRIER_VIEW: 'dashboardUseCarrierView',
    COMMERCIAL_DASHBOARD_CLIENT_TAB: 'commercialDashboardClientTab',
    COMMERCIAL_DASHBOARD_OPERATIVE_TAB: 'commercialDashboardOperativeTab',
    ADMINISTRATIVE_DASHBOARD_SELLS_TAB: 'administrativeDashboardSellsTab',
    ADMINISTRATIVE_DASHBOARD_COSTS_TAB: 'administrativeDashboardCostsTab',
    SORT: 'sort',
    WEEK: 'week',
    VISUALIZING: 'visualizing',
    MISSING_SEARCH_PARAMS: 'missingSearchParams',
};

export const SUPPORTED_LANGUAGES = {
    SPANISH: 'es',
    ENGLISH: 'en',
    PORTUGUESE: 'pt',
};

export const SUPPORTED_LANGUAGES_OPTIONS = [
    {value: SUPPORTED_LANGUAGES.SPANISH, label: "Español"},
    {value: SUPPORTED_LANGUAGES.ENGLISH, label: "English"},
    {value: SUPPORTED_LANGUAGES.PORTUGUESE, label: "Português"},
];

export const SUPPORTED_CURRENCIES_FORMATTERS = {
    USA: 'usa',
    LOCALE: 'locale',
}

export const SUPPORTED_CURRENCIES_FORMATTERS_OPTIONS = [
    {value: SUPPORTED_CURRENCIES_FORMATTERS.USA, label: "Formato USA"},
    {value: SUPPORTED_CURRENCIES_FORMATTERS.LOCALE, label: "Formato Local"},
]

export const CONDITIONS = {
    EQUALS: 'EQUALS',
    NOT_EQUALS: 'NOT_EQUALS',
    CONTAINS: 'CONTAINS',
    HAS: 'HAS',
    NOT_HAS: 'NOT_HAS',
};

export const CONDITIONS_OPTIONS = [
    {value: CONDITIONS.EQUALS, label: 'Igual a'},
    {value: CONDITIONS.NOT_EQUALS, label: 'Distinto de'},
    {value: CONDITIONS.CONTAINS, label: 'Contiene'},
    {value: CONDITIONS.HAS, label: 'Tiene'},
    {value: CONDITIONS.NOT_HAS, label: 'No tiene'},
];

export const FILTER_TYPES = {
    INCOTERM: 'INCOTERM',
    ORIGIN: 'ORIGIN',
    DESTINATION: 'DESTINATION',
    AUTORATE: 'AUTORATE',
    TRANSPORT_MODE: 'TRANSPORT_MODE',
    MODE: 'MODE',
}

export const FILTER_TYPES_OPTIONS = [
    {value: FILTER_TYPES.ORIGIN, label: 'Origen'},
    {value: FILTER_TYPES.DESTINATION, label: 'Destino'},
    {value: FILTER_TYPES.INCOTERM, label: 'Incoterm'},
    {value: FILTER_TYPES.AUTORATE, label: 'Auto Rate'},
    {value: FILTER_TYPES.TRANSPORT_MODE, label: 'Transporte'},
    {value: FILTER_TYPES.MODE, label: 'Modo'},
];

export const DRAFT_TYPES = {
    NEW_QUOTATION: 'NEW_QUOTATION',
    NEW_EXPRESS: 'NEW_EXPRESS',
    NEW_PRICING: 'NEW_PRICING',
}

export const ROUTES = {
    HOME: {path: "/home", permission: "", label: "Home"},
    LOGIN: {path: "/login", permission: "", label: "Login"},
    GENERAL_DASHBOARD: {
        path: "/general-dashboards",
        permission: PERMISSION.ANALYTICS_QUOTATIONS,
        label: "Dashboard General"
    },
    // COMMERCIAL_DASHBOARD: {path: "/commercial-dashboards", permission: PERMISSION.ANALYTICS_QUOTATIONS, label: "Dashboard Comercial"},
    // ADMINISTRATIVE_DASHBOARD: {path: "/administrative-dashboards", permission: PERMISSION.ANALYTICS_QUOTATIONS, label: "Dashboard Administrativo"},
    REPORTS: {path: "/reports", permission: PERMISSION.ANALYTICS_REPORTS, label: "Reportes"},
    MY_QUOTATIONS: {path: "/mis-cotizaciones", permission: PERMISSION.COMERCIAL, label: "Mis Cotizaciones"},
    MY_QUOTATIONS_HISTORY: {
        path: "/mis-cotizaciones/historial",
        permission: PERMISSION.COMERCIAL,
        label: "Mis Cotizaciones Historial"
    },
    FINALIZE_QUOTE: {path: "/finalizar", permission: PERMISSION.COMERCIAL},
    ASSIGNED: {path: "/asignadas", permission: PERMISSION.COMERCIAL, label: "Asignadas"},
    QUOTATIONS_MANAGER: {
        path: "/cotizaciones-gerente",
        permission: PERMISSION.GERENTE_COMERCIAL,
        label: "Cotizaciones"
    },
    PRICING_NEW_REQUESTS: {
        path: "/pricing/nuevas-solicitudes",
        permission: PERMISSION.PRICING,
        label: "Nuevas Solicitudes"
    },
    PRICING_IN_PROGRESS: {path: "/pricing/en-proceso", permission: PERMISSION.PRICING, label: "En Proceso"},
    PRICING_QUOTATIONS: {path: "/pricing/cotizaciones", permission: PERMISSION.PRICING, label: "Cotizaciones"},
    PRICING_QUOTE: {path: "/pricing/cotizador", permission: PERMISSION.PRICING, label: "Nueva Cotización"},
    ORGANIZATIONS: {path: "/organizaciones", permission: PERMISSION.CONSULTAR_ORGANIZACION, label: "Organizaciones"},
    PROVIDERS: {path: "/proveedores", permission: PERMISSION.PRICING, label: "Proveedores"},
    CHARGE_CODES: {path: "/charge-codes", permission: PERMISSION.ALTA_CHARGE_CODE, label: "Charge Codes"},
    USERS: {path: "/usuarios", permission: PERMISSION.MODIFICAR_USUARIO, label: "Usuarios"},
    EXPRESS_OPERATIONS: {path: "/operativa/express", permission: PERMISSION.CONSULTAR_SHIPMENT, label: "Express"},
    EXPRESS_OPERATIONS_IN_PROGRESS: {
        path: "/operativa/express/en-proceso",
        permission: PERMISSION.CONSULTAR_SHIPMENT,
        label: "En proceso"
    },
    EXPRESS_OPERATIONS_FINISHED: {
        path: "/operativa/express/finalizadas",
        permission: PERMISSION.CONSULTAR_SHIPMENT,
        label: "Finalizadas"
    },
    PRICING_RATES: {path: "/pricing/tarifas", permission: PERMISSION.PRICING, label: "Tarifas"},
    PRICING_QUOTATION_RULES: {
        path: "/pricing/reglas-cotizacion",
        permission: PERMISSION.PRICING_RULES,
        label: "Reglas de Cotización"
    },
    DRAFTS: {path: "/borradores", permission: PERMISSION.CONSULTAR_COTIZACION, label: "Borradores"},
    NOTES: {path: "/notas", permission: PERMISSION.PRICING, label: "Notas"},
    THIRD_PARTY: {path: "/third-party", permission: "", label: "Third Party"},
}

export const AUTO_INCREMENT_STEP = 1;

export const MAX_UPLOAD_SIZE = {label: '20MB', value: 20 * 1024 ** 2}

export const NO_RESULTS_FOUND_OPTION = {value: '', label: "No se encontraron resultados", disabled: true};

export const NOTES_TYPES = {
    CLIENT: "CLIENT",
    INTERNAL: "INTERNAL",
    ON_REQUEST: "ON_REQUEST"
}

export const STACKABLE_TYPES = {
    YES: "YES",
    NO: "NO",
    TOP_LOAD: "TOP_LOAD",
}

export const STACKABLE_OPTIONS = [
    {value: STACKABLE_TYPES.YES, label: "Si"},
    {value: STACKABLE_TYPES.NO, label: "No"},
    {value: STACKABLE_TYPES.TOP_LOAD, label: "Top Load"},
]

export const DASHBOARD_SERVICE_OPTIONS = [
    {value: "FCL", label: "Maritimo - FCL"},
    {value: "LCL", label: "Maritimo - LCL"},
    {value: "RORO", label: "Maritimo - RORO"},
    {value: "ULD", label: "Aéreo - ULD"},
    {value: "LSE", label: "Aéreo - LSE"},
    {value: "FTL", label: "Terrestre - FTL"},
    {value: "LTL", label: "Terrestre - LTL"},
]

export const DASHBOARD_INCOTERM_OPTIONS = [
    {value: "EXW", label: "EXW"},
    {value: "FCA", label: "FCA"},
    {value: "CPT", label: "CPT"},
    {value: "CIP", label: "CIP"},
    {value: "DAP", label: "DAP"},
    {value: "DPU", label: "DPU"},
    {value: "DDP", label: "DDP"},
    {value: "CFR", label: "CFR"},
    {value: "FOB", label: "FOB"},
    {value: "FAS", label: "FAS"},
    {value: "CIF", label: "CIF"},
]

export const DASHBOARD_CONTAINER_OPTIONS = [
    {value: '20FR', label: '20FR'},
    {value: '20GP', label: '20GP'},
    {value: '20HC', label: '20HC'},
    {value: '20NOR', label: '20NOR'},
    {value: '20OT', label: '20OT'},
    {value: '20PL', label: '20PL'},
    {value: '20RE', label: '20RE'},
    {value: '40FR', label: '40FR'},
    {value: '40GP', label: '40GP'},
    {value: '40HC', label: '40HC'},
    {value: '40NOR', label: '40NOR'},
    {value: '40OT', label: '40OT'},
    {value: '40PL', label: '40PL'},
    {value: '40RE', label: '40RE'},
    {value: '40REHC', label: '40REHC'},
    {value: '45HC', label: '45HC'}
];

export const DASHBOARD_TRANSPORT_MODE_OPTIONS = [
    {value: 'AIR', label: 'Aéreo'},
    {value: 'SEA', label: 'Marítimo'},
    {value: 'ROAD', label: 'Terrestre'},
    {value: 'EXPRESS', label: 'Express'},
]

export const DASHBOARD_STATUS_OPTIONS = [
    {value: 'REQUESTED', label: 'Solicitado'},
    {value: 'PENDING', label: 'Pendiente'},
    {value: 'QUOTED', label: 'Cotizado'},
    {value: 'UPDATED', label: 'Actualizado'},
    {value: 'SENT', label: 'Enviado'},
    {value: 'APPROVED', label: 'Aprobado'},
    {value: 'NOT_APPROVED', label: 'No Aprobado'},
    {value: 'FUTURE', label: 'A futuro'},
]

export const DASHBOARD_CARRIER_OPTIONS = [
    {value: '0', label: 'carrier-0'},
    {value: '1', label: 'carrier-1'},
    {value: '2', label: 'carrier-2'},
    {value: '3', label: 'carrier-3'},
    {value: '4', label: 'carrier-4'},
]

export const COMPANY_OPTIONS = [
    {value: 'MVD', label: 'MVD'},
    {value: 'AUZ', label: 'AUZ'}
]

export const AIR_MODE_OPTIONS = [
    {value: 'LSE', label: 'LSE'},
    {value: 'ULD', label: 'ULD'},
]

export const CURRENT_DATE = new Date()
export const INITIAL_DATE = new Date(CURRENT_DATE.getFullYear(), CURRENT_DATE.getMonth(), 1)

export const DASHBOARD_GLOBAL_FILTERS_INITIAL_STATE = {
    month: {
        key: 'month',
        selectedValue: [INITIAL_DATE, CURRENT_DATE],
        useGlobal: true,
        values: []
    },
    commercial: {
        key: 'commercial',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    transportMode: {
        key: 'transportMode',
        selectedValue: [],
        useGlobal: true,
        values: DASHBOARD_TRANSPORT_MODE_OPTIONS
    },
    provider: {
        key: 'provider',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    incoterms: {
        key: 'incoterms',
        selectedValue: [],
        useGlobal: true,
        values: DASHBOARD_INCOTERM_OPTIONS
    },
    origin: {
        key: 'origin',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    origins: {
        key: 'origins',
        selectedValue: [],
        useGlobal: true,
        values: []
    },
    destination: {
        key: 'destination',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    destinations: {
        key: 'destinations',
        selectedValue: [],
        useGlobal: true,
        values: []
    },
    container: {
        key: 'container',
        selectedValue: null,
        useGlobal: true,
        values: DASHBOARD_CONTAINER_OPTIONS
    },
    company: {
        key: 'company',
        selectedValue: null,
        useGlobal: true,
        values: COMPANY_OPTIONS
    },
    carrier: {
        key: 'carrier',
        selectedValue: null,
        useGlobal: true,
        values: DASHBOARD_CARRIER_OPTIONS
    },
    transitTime: {
        key: 'transitTime',
        selectedValue: null,
        useGlobal: true,
        values: null,
    },
    weightRange: {
        key: 'weightRange',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    volumeRange: {
        key: 'volumeRange',
        selectedValue: null,
        useGlobal: true,
        values: []
    },
    commodities: {
        key: 'commodities',
        selectedValue: [],
        useGlobal: true,
        values: []
    },
    airMode: {
        key: 'airMode',
        selectedValue: AIR_MODE_OPTIONS[0].value,
        useGlobal: true,
        values: AIR_MODE_OPTIONS
    },
}

export const SORT_OPTIONS = [
    {value: 'ASC', label: "Menor a mayor"},
    {value: 'DESC', label: "Mayor a menor"},
    {value: 'NONE', label: "Por defecto"}
]

export const DASHBOARD_TABS_OPTIONS = {
    GENERAL: 'GENERAL',
    LSE: 'LSE',
    ULD: 'ULD',
    LCL: 'LCL',
    FCL: 'FCL',
    CLIENTS_PORTFOLIO: 'CLIENT_PORTFOLIO',
    OPERATIVE: 'OPERATIVE',
    PENDING_SELLS: 'PENDING_SELLS',
    PENDING_COSTS: 'PENDING_COSTS',
}

export const ALTERNATIVE_CARD_FIXED_HEIGHT = 36;

export const POSTAL_AWARE_COUNTRIES = ["AU", "AT", "BE", "BR", "CA", "CL", "CN", "CO", "CZ", "CZ", "DK", "EC", "FI", "FR", "DE", "GR", "GR", "HU", "IN", "ID", "IT", "JP", "KR", "LU", "MY", "MX", "NL", "NL", "NO", "PH", "PL", "PT", "US", "RO", "RU", "RU", "SA", "SG", "SK", "SK", "ZA", "ES", "SE", "CH", "TH", "TR", "GB", "GB", "GB", "GB", "US"]